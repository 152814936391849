import logo from './images/logo.png';
import styles from './App.module.css';
import React, { useState, useRef, useEffect } from 'react'
import Fade from '@mui/material/Fade';
import api from './Services/Api';
import Cookies from 'js-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import Alert from '@mui/material/Alert';
import FeedbackIcon from '@mui/icons-material/Feedback';
import AccountIcon from './Account/AccountIcon';
function App() {
    let query = useQuery();
    const navigate = useNavigate();
    const [noCreditsLeft, setNoCreditsLeft] = useState(false);

    useEffect(() => {
        api.getUserData().then((response) => {
            navigate("/app");
        }).catch((error) => {
            console.log("-------- ERROR CREDITOS!!! --------\n", JSON.stringify(error))
            try {
                if (error.response.status == 401 && error.response.data.message != 'Unauthorized') {
                    setNoCreditsLeft(true);
                }
            } catch (error) {
                console.log("Error parsing error", error)
            }
            
            logout();
        });
    }, []);

    function logout() {
        api.logout();
        navigate("/");
    }
    
    function login() {
        window.location.replace(`${api.baseURL}/install`)
    }

    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    return (
        <div className={styles.BackgroundGlobal}>
            
            <div className={styles.middleContainer}>
                <Fade in={true} timeout={3000} easing={1000}>
                    <img id="logo" src={logo} alt="logo" style={{ width: "40%", maxWidth: "300px", transition: "all 1s ease-in-out" }} />
                </Fade>
                {!noCreditsLeft && <Button variant="contained" size="large" color="info" onClick={() => { login() }} style={{ marginTop: "2rem", color: "#002657" }}>Iniciar sesión</Button>}
            </div>
            {noCreditsLeft &&
                <Alert severity="error" style={{ position: "fixed", bottom: "5%", width: "90%", left: "5%", right: "5%" }}
                    icon={<FeedbackIcon fontSize="inherit" />}>No tienes créditos disponibles</Alert>}
        </div>
    );
}

export default App;
