import React, { useState, useEffect, useRef } from 'react';
import { TextField, Paper, Stack, Typography, Grid, Button, Drawer, FormControl, InputLabel, Select, Box, MenuItem, Alert, InputAdornment } from '@mui/material';
import api from '../Services/Api';
import AudioRecorderButton from '../components/AudioRecorderButton';
import { useNavigate, useLocation } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import Companies from './Companies';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

const Contacts = () => {
    const { state } = useLocation();

    const crmUpdated = state != undefined ? state.ok : false
    const [filteredContacts, setFilteredContacts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    var previousController = useRef();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [createdate, setCreatedate] = useState("DESCENDING");

    const navigate = useNavigate();
    useEffect(() => {
        api.fetchContacts({ firstName: undefined, after: 0, createdate: createdate }).then((response) => {
            setFilteredContacts(response.options);
        }).catch((error) => {
            console.error("Error fetching contacts", error);
        });
    }, []);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        if (previousController.current) {
            previousController.current.abort();
        }
        const controller = new AbortController();
        const signal = controller.signal;
        previousController.current = controller;

        api.fetchContacts({ firstName: event.target.value.toLowerCase(), after: 0, createdate: createdate, signal: signal }).then((response) => {
            setFilteredContacts(response.options);
        }).catch((error) => {
            console.error("Error fetching contacts", error);
        });
    };

    const handleAudioSent = (text, contact) => {
        let company = contact.company;
        var object = { contact: contact };
        if (company != undefined) {
            object.company = company;
        }
        navigate("/app/validate", { state: { text: text, json: object, routeBack: "/app/contacts" } });
    }


    const handleSortChange = () => {
        api.fetchContacts({ firstName: searchTerm, after: 0, createdate: createdate }).then((response) => {
            setFilteredContacts(response.options);
        }).catch((error) => {
            console.error("Error fetching contacts", error);
        });
    }

    return (
        <Stack style={{ padding: '1rem', height: 'calc(100% - 2rem)', maxWidth: '100%', margin: 'auto' }}>
            {crmUpdated &&
                <Alert style={{
                    backgroundColor: "#366E6B", color: "white", position: "absolute", width: "90%", left: "5%", right: "5%",
                    top: "5%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", textAlign: "center"
                }}
                    icon={<CheckIcon style={{ color: "white" }} fontSize="inherit" />}>
                    CRM actualizado correctamente
                </Alert>
            }
            <TextField
                variant="outlined"
                fullWidth
                value={searchTerm}
                onChange={handleSearchChange}
                slotProps={{
                    input: {
                      startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                      endAdornment: <InputAdornment position="end">{searchTerm && searchTerm.length > 0 && <ClearIcon onClick={() => { 
                        setSearchTerm(''); 
                        handleSearchChange({ target: { value: '' } });
                    }} />} </InputAdornment>,
                    }
                }}
                style={{ marginBottom: '1rem' }}
            />
            <Button variant="outlined" onClick={() => setDrawerOpen(true)}>
                Ordenación
            </Button>
            <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
                <Typography variant="h6" p={2}>Ordenación</Typography>
                <Box p={2} style={{ width: '100%' }} role="presentation">
                    <Typography variant="h6">Fecha de creación</Typography>
                    <FormControl fullWidth margin="normal">
                        <Select
                            value={createdate}
                            onChange={(e) => setCreatedate(e.target.value)}
                        >
                            <MenuItem value="ASCENDING">Más antiguo</MenuItem>
                            <MenuItem value="DESCENDING">Más nuevo</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box p={2} style={{ width: '100%', position: 'absolute', bottom: 0 }} role="presentation">
                    <Button
                        fullWidth

                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setDrawerOpen(false);
                            handleSortChange();
                        }}
                    >
                            Aplicar
                        </Button>
                    </Box>
            </Drawer>
            <Grid container spacing={2}>
                {filteredContacts.map((contact) => (
                    <Grid item xs={6} sm={6} md={4} lg={3}>
                        <AudioRecorderButton
                            title={`${contact.firstname} ${contact.lastname}`}
                            handleAudioSent={(text) => {
                                handleAudioSent(text, contact)
                            }}
                            renderContent={({ handleClickOpenInternal }) => {
                                return <ContactCard contact={contact} handleClickOpenInternal={handleClickOpenInternal} />
                            }}
                            renderContentBig={() => {
                                return <ContactCardBig contact={contact} />
                            }}
                        />
                    </Grid>
                ))}
            </Grid>

        </Stack>
    );
};

const ContactCard = ({ contact, handleClickOpenInternal }) => {
    const getContactName = (contact) => {
        var name = `${contact.firstname ?? ""} ${contact.lastname ?? ""}`
        if (name.trim().length == 0 || name.includes("null")) {
            name = "--"
        }
        return name
    }
    return <Stack onClick={handleClickOpenInternal} direction="column" alignItems="leading" spacing={0} style={{ maxWidth: '98%' }}>
        <Typography noWrap={true} variant="h6">{getContactName(contact)}</Typography>
        <Typography noWrap={true} variant="caption">{contact.email ?? "--"}</Typography>
        <Typography noWrap={true} variant="caption">{contact.phone ?? "--"}</Typography>
        <Typography noWrap={true} variant="caption">{contact.company != undefined && contact.company.name != undefined ? contact.company.name : "--"}</Typography>
    </Stack>
}

const ContactCardBig = ({ contact }) => {
    const getContactName = (contact) => {
        var name = `${contact.firstname ?? ""} ${contact.lastname ?? ""}`
        if (name.trim().length == 0 || name.includes("null")) {
            name = "--"
        }
        return name
    }
    return <Stack direction="column" alignItems="leading" spacing={0} style={{ maxWidth: '98%' }}>
        <Typography noWrap={true} variant="h6">{getContactName(contact)}</Typography>
        <Typography noWrap={true} variant="caption">{contact.email ?? "--"}</Typography>
        <Typography noWrap={true} variant="caption">{contact.phone ?? "--"}</Typography>
        {contact.company && <Companies.CompanyCard company={contact.company} />}
    </Stack>
}

export default { Contacts, ContactCard, ContactCardBig };