import React, { useEffect, useRef, useState } from 'react';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid2'; // Grid version 2
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import PauseIcon from '@mui/icons-material/Pause';
import PlayIcon from '@mui/icons-material/PlayArrow';
import Fade from '@mui/material/Fade';

const Controls = ({ onClear, onRecord, onPause, onFinish, recording, recordInProgress }) => {
    const [started, setStarted] = useState(false)

    return (
        <Grid container spacing={3} display="flex" justifyContent="center" alignItems="center" style={{ width: "100%" }}>
            <Grid xs display="flex" justifyContent="center" alignItems="center">
                <Fab color="info" size="medium" aria-label="clear" onClick={() => {
                    setStarted(false)
                    onClear();
                }}>
                    <ClearIcon color="white" />
                </Fab>
            </Grid>
            <Grid xs display="flex" justifyContent="center" alignItems="center">
                <Fab color="info" aria-label="record" size="large" onClick={() => {
                    if (!recording && !recordInProgress) {
                        onRecord()
                    } else {
                        onPause()
                    }
                    setStarted(!started)
                }} >
                    {recording && !started && <PauseIcon color="white" />}
                    {!recording && started && <PlayIcon color="white" />}
                </Fab>
            </Grid>
            <Grid xs display="flex" justifyContent="center" alignItems="center">
                <Fab size="medium" color="info" aria-label="done" onClick={() => {
                    onFinish()
                    setStarted(false)
                }}>
                    <CheckIcon color="white" />
                </Fab>
            </Grid>
        </Grid>
    );
};

export default Controls;
