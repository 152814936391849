import { Button, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import logo from '../images/logo.png';
import wave from '../images/wave.svg';
import AccountComponent from './AccountComponent';
import { Navigate, useNavigate } from 'react-router-dom';
import Api from '../Services/Api';

const Account = () => {
    const [user, setUser] = useState(null)
    const navigate = useNavigate();
    useEffect(() => {
        Api.getUserData().then((response) => {
            setUser(response)
        }).catch((error) => {
            navigate("/")
        });
    }, []);
    
    return (
        <div style={{ margin: 10, flexDirection: "column", display: "flex", justifyContent: "center", height: "100vh", marginTop: "-10%" }}>
            <Stack spacing={2} alignItems="center">
                <img id="logo" src={logo} alt="logo" style={{ width: "25%", maxWidth: "300px", transition: "all 1s ease-in-out" }} />
                <AccountComponent user={user}/>
            </Stack>
        </div>
        
    );
};

export default Account;