import { Button, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import api from '../Services/Api';
import Cookies from 'js-cookie';
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const AccountComponent = ({user, onLogout}) => {
    const navigate = useNavigate();
    
    function logout() {
        api.logout();
        navigate("/");
        Cookies.remove('token');
        if (onLogout) {
            onLogout();
        }
        window.location.reload();
    }
    return (
        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            <Stack spacing={2} style={{padding: 10}} alignItems="center">
                {user &&
                    <div style={{ width: "100%", color: 'black', textAlign: 'center', fontWeight: 'bold', color: "white" }}>
                        <h2>{user.name}</h2>
                        <h3>{user.domain}</h3>
                        <h4>{user.email}</h4>
                    </div>
                }
                <Button variant="contained" color="error" onClick={() => {logout()}}>Cerrar sesión</Button>
            </Stack>
        </div>
    );
}

export default AccountComponent;