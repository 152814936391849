import React, { useState, useEffect, useRef } from 'react';
import { TextField, Paper, Stack, Typography, Grid, Chip, Button, Drawer, Box, FormControl, Select, MenuItem, InputLabel, Alert, InputAdornment } from '@mui/material';
import api from '../Services/Api';
import AudioRecorderButton from '../components/AudioRecorderButton';
import { useNavigate, useLocation } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

const Companies = () => {
    const { state } = useLocation();

    const crmUpdated = state != undefined ? state.ok : false
    const [companies, setCompanies] = useState([]);
    const [filteredCompanies, setFilteredCompanies] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [createdate, setCreatedate] = useState("DESCENDING");

    const previousController = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        api.fetchCompanies({createdate: createdate}).then((response) => {
            setCompanies(response.options);
            setFilteredCompanies(response.options);
        }).catch((error) => {
            console.error("Error fetching companies", error);
        });
    }, []);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        if (previousController.current) {
            previousController.current.abort();
        }
        const controller = new AbortController();
        const signal = controller.signal;
        previousController.current = controller;

        api.fetchCompanies({
            value: event.target.value.toLowerCase(),
             createdate: createdate,
             after: 0,
             signal: signal
            }).then((response) => {
            setPage(0);
            setFilteredCompanies(response.options);
        }).catch((error) => {
            console.error("Error fetching companies", error);
        });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        var after = 0
        if (newPage * rowsPerPage < 100) {
            return
        } else {
            after = newPage * rowsPerPage
            api.fetchCompanies({
                value: searchTerm.toLowerCase(),
                after: after,
                createdate: createdate
            }).then((response) => {
                setFilteredCompanies([...filteredCompanies, ...response.options]);
            }).catch((error) => {
                console.error("Error fetching companies", error);
            });
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleAudioSent = (text, company) => {
        navigate("/app/validate", { state: { text: text, json: { company: company }, routeBack: "/app/companies" } });
    }

    const handleSortChange = () => {
        api.fetchCompanies({
            value: searchTerm.toLowerCase(),
            after: 0,
            createdate: createdate
        }).then((response) => {
            setFilteredCompanies(response.options);
        }).catch((error) => {
            console.error("Error fetching contacts", error);
        });
    }

    return (
        <Stack style={{ padding: '1rem', width: '100%', margin: 'auto' }}>
            {crmUpdated &&
        <Alert style={{
          backgroundColor: "#366E6B", color: "white", position: "absolute", width: "90%", left: "5%", right: "5%",
          top: "5%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", textAlign: "center"
        }}
          icon={<CheckIcon style={{ color: "white" }} fontSize="inherit" />}>
          CRM actualizado correctamente
            </Alert>
            }
            <TextField
                variant="outlined"
                fullWidth
                value={searchTerm}
                slotProps={{
                    input: {
                      startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                      endAdornment: <InputAdornment position="end">{searchTerm && searchTerm.length > 0 && <ClearIcon onClick={() => { 
                        setSearchTerm(''); 
                        handleSearchChange({ target: { value: '' } });
                    }} />} </InputAdornment>,
                    }
                }}
                onChange={handleSearchChange}
                style={{ marginBottom: '1rem' }}
            />
            <Button variant="outlined" onClick={() => setDrawerOpen(true)}>
                Ordenación
            </Button>
            <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
                <Typography variant="h6" p={2}>Ordenación</Typography>
                <Box p={2} style={{ width: '100%' }} role="presentation">
                    <Typography variant="h6">Fecha de creación</Typography>
                    <FormControl fullWidth margin="normal">
                        <Select
                            value={createdate}
                            
                            onChange={(e) => setCreatedate(e.target.value)}
                        >
                            <MenuItem value="ASCENDING">Más antigua</MenuItem>
                            <MenuItem value="DESCENDING">Más nueva</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box p={2} style={{ width: '100%', position: 'absolute', bottom: 0 }} role="presentation">
                <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setDrawerOpen(false);
                            handleSortChange();
                        }}
                    >
                        Aplicar
                    </Button>
                </Box>
            </Drawer>
            <Grid container spacing={0}>
            {filteredCompanies.map((company) => (
                <Grid item xs={6} sm={6} md={4} lg={3}>
                <AudioRecorderButton 
                            title={company.name}
                            handleAudioSent={(text) => {
                                handleAudioSent(text, company)
                            }}
                            renderContent={({handleClickOpenInternal}) => {
                                return <CompanyCard company={company} handleClickOpenInternal={handleClickOpenInternal}/>
                            }}
                            renderContentBig={() => {
                                return <CompanyCard company={company} />
                            }}
                        />
                </Grid>
            ))}
            </Grid>

        </Stack>
    );
};

const CompanyCard = ({ company, handleClickOpenInternal }) => {
    return <Stack onClick={()=> { if (handleClickOpenInternal) { handleClickOpenInternal() } }} direction="column" alignItems="leading" spacing={0.5} style={{ maxWidth: '98%' }}>
    <Typography noWrap={true} variant="h6">{company.name ?? "--"}</Typography>
    <Typography noWrap={true} variant="caption">{company.phone ?? "--"}</Typography>
    <Typography noWrap={true} variant="caption">{company.domain ?? "--"}</Typography>
    <Typography noWrap={true} variant="caption">{company.address}</Typography>
    <Grid container spacing={1}>
        <Grid item>
            {company.city && <Chip label={company.city ?? "--"} variant="outlined" color="primary" />}
        </Grid>
        <Grid item>
            {company.country && <Chip label={company.country ?? "--"} variant="outlined" color="secondary" />}
        </Grid>
    </Grid>
</Stack>
}

export default {
    Companies,
    CompanyCard
}; 
