import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Recorder from './Recorder';
import Api from '../Services/Api';
import * as Sentry from "@sentry/browser";
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import ProcessingComponent from '../designsystem/ProcessingComponent';
import { Paper, Stack } from '@mui/material';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<unknown>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const CircularButton = styled(Button)({
    borderRadius: '50%',
    padding: 0,
    minWidth: 0,
    width: 50,
    height: 50,
    overflow: 'hidden',
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const AudioRecorderButton = ({ handleAudioSent, title, renderContent, renderContentBig }) => {
    const [open, setOpen] = useState(false);
    const [processing, setProcessing] = useState(false);

    const handleClickOpenInternal = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onUploadProgress = (progressEvent) => {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        console.log(percentCompleted);
        if (percentCompleted === 100) {
        } else {
        }
    };

    const finished = (audio) => {
        setProcessing(true);
        Api.UploadAudio(audio, onUploadProgress).then((response) => {
            handleAudioSent(response.text);
            setProcessing(false);
        }).catch((error) => {
            Sentry.captureException(error);
        });
    };

    return (
        <React.Fragment>
            <Paper style={{ borderRadius: '1.5rem', padding: '1rem', margin: '0.25rem' }} elevation={3} variant="outlined" square={false}>
                {renderContent && renderContent({ handleClickOpenInternal })}
            </Paper>
            <BootstrapDialog
                fullScreen
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                TransitionComponent={Transition}
            >
                <DialogTitle id="customized-dialog-title">
                    {title}
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={(theme) => ({
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: theme.palette.grey[500],
                        })}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Stack direction="column" spacing={2} alignItems="center" style={{ padding: '1rem', width: '100%' }}>
                        {!processing && <Recorder finished={finished} animation={false} onClear={() => { }} onStart={() => { }} small={true} />}
                        {processing && <ProcessingComponent processingText="Procesando audio..." />}
                        {renderContentBig && renderContentBig()}
                    </Stack>
                </DialogContent>
            </BootstrapDialog>
        </React.Fragment>
    );
};

export default AudioRecorderButton;
