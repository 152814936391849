import React, { useEffect, useState, useRef } from 'react';
import { Button, Grid, Paper, Stack, Typography } from '@mui/material';
import ContactsIcon from '@mui/icons-material/Contacts';
import BusinessIcon from '@mui/icons-material/Business';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Outlet, useNavigate } from 'react-router-dom';
import AccountIcon from '../Account/AccountIcon';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import EuroIcon from '@mui/icons-material/Euro';
import MicIcon from '@mui/icons-material/Mic';
import logo from '../images/logo.png';
import '../index.css'
import Api from '../Services/Api';
const Layout = ({ children }) => {
  const navigate = useNavigate();
  const [size, setSize] = useState(0);
  const [logged, setLogged] = useState(false);
  const sheetRef = useRef(null);
  const bottomNavigationItems = [
    { label: 'Contactos', icon: <ContactsIcon />, path: '/app/contacts' },
    { label: 'Negocios', icon: <EuroIcon />, path: '/app/deals' },
    { label: 'Compañias', icon: <BusinessIcon />, path: '/app/companies' },
    { label: 'Cuenta', icon: <AccountCircleIcon />, path: '/account' },
  ]

  useEffect(() => {
    Api.getUserData().then((response) => {
      setLogged(true)
    }).catch((error) => {
      setLogged(false)
      navigate("/")
    })
  }, [])

  return (
    <div style={{ position: 'relative' }}>
      <div style={{ marginBottom: size }}>
        <Outlet />
      </div>
      {logged && <BottomSheet
        open
        ref={sheetRef}
        blocking={false}
        expandOnContentDrag={true}
        snapPoints={({ maxHeight }) => {
          console.log(maxHeight)
          var size = maxHeight * 0.08
          var paddingSize = size * 1.1
          const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
          if (!isMobile) {
            size = 70
            paddingSize = 80
          }
          setSize(paddingSize)
          return [size, maxHeight * 0.8]
        }
      }
        header={<Typography variant="h6">Menu</Typography>}
      >
        <Grid container>
          <Grid item xs={12} padding={1}>
          <Button
                style={{
                  backgroundColor: '#002657',
                  width: '100%',
                  height: '100%',
                  color: 'white',
                  borderRadius: '10px',
                }}
                onClick={() => {
                  sheetRef.current.snapTo(({ snapPoints }) =>
                    Math.min(...snapPoints)
                  )
                  navigate("/app")
                  }}>
                <Stack 
                  spacing={2}
                  padding={2}
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <img src={logo} alt="Labia" style={{ width: '15%', height: '15%' }} />
                  <Typography variant="h6"> Labia </Typography>
                </Stack>
              </Button>
            
          </Grid>

          {bottomNavigationItems.map((item, index) => (
            <Grid item xs={6} padding={1}>
              <Button
                style={{
                  backgroundColor: '#002657',
                  width: '100%',
                  height: '100%',
                  color: 'white',
                  borderRadius: '10px',
                }}
                onClick={() => {
                  sheetRef.current.snapTo(({ snapPoints }) =>
                    Math.min(...snapPoints)
                  )
                  navigate(item.path)
                }}>
                <Stack 
                  spacing={2}
                  padding={2}
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  {item.icon}
                  <Typography variant="h6"> {item.label} </Typography>
                </Stack>
              </Button>
            </Grid>
          ))}
        </Grid>


      </BottomSheet>}
    </div>
  );
};

export default Layout;