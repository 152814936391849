import React, { useState, useEffect, useRef } from 'react';
import { TextField, Paper, Stack, Grid, Typography, Chip, Divider, Select, MenuItem, Tabs, Tab, Dialog, DialogContent, DialogTitle, DialogContentText, Button, DialogActions, CircularProgress, Backdrop, Alert, InputAdornment } from '@mui/material';
import { Drawer, FormControl, InputLabel, Box } from '@mui/material';
import dayjs from 'dayjs';
import api from '../Services/Api';
import AudioRecorderButton from '../components/AudioRecorderButton';
import { useNavigate, useLocation } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import Contacts from './contacts';
import Api from '../Services/Api';

const Deals = () => {
    const { state } = useLocation();

    const crmUpdated = state != undefined ? state.ok : false
    const [filteredDeals, setFilteredDeals] = useState([]);
    const [deals, setDeals] = useState([]);
    const [pipeline, setPipeline] = React.useState(undefined);
    const [pipelines, setPipelines] = React.useState([]);
    const [stage, setStage] = React.useState(-1);
    const [stages, setStages] = React.useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [dealSelected, setDealSelected] = useState(null);
    const [closedate, setClosedate] = useState("DESCENDING");
    const [drawerOpen, setDrawerOpen] = useState(false);
    const previousController = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        fetchDeals();    
    }, []);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setPipeline(undefined);
        setStage(-1);
        fetchDeals(event.target.value, false);
    };

    const handleAudioSent = (text, contact) => {
        navigate("/app/validate", { state: { text: text, json: { contact: contact }, routeBack: "/app/deals" } });
    }

    const handleChangePipeline = (event) => {
        var newPipeline = event.target.value;
        let newStage = deals.find((option) => { return option.pipeline.id == newPipeline }).stages[0].id;
        filterDeals(newPipeline, newStage, deals);
    };


    const handleChangeStage = (event, newStage) => {
        setStage(newStage);
        filterDeals(pipeline, newStage, deals);
    };

    const loadDeal = (deal, handleClickOpenInternal) => {
        setLoading(true);
        Api.fetchDeal(deal.id).then((response) => {
            setDealSelected(response);
            handleClickOpenInternal();
            setLoading(false);
        }).catch((error) => {
            console.error("Error fetching deal", error);
            setLoading(false);
        });
    }

    const handleSortChange = () => {
        fetchDeals();
    }

    const fetchDeals = (search, filterPipeline = true) => {
        setLoading(true);
        if (previousController.current) {
            previousController.current.abort();
        }
        const controller = new AbortController();
        const signal = controller.signal;
        previousController.current = controller;
        var options = {
            after: 0, 
            closedate: closedate, 
            signal: signal
        }
        if (search && search.length > 0 || searchTerm && searchTerm.length > 0) {
            if (search != undefined) {
                options.value = search;
            } else {
                options.value = searchTerm;
            }
        }

        api.fetchDealsGrouped(options).then((response) => {
            if (response.options.length > 0) {
                let pipelineId = response.options[0].pipeline.id
                let pipe = filterPipeline ? pipeline || pipelineId : pipelineId;
                let options = response.options.find((option) => { return option.pipeline.id == pipe });
                let stages = options.stages;
                let stage = stages[0];
                setDeals(response.options);
                setStages(stages);
                setPipelines(response.options.map((option) => { return option.pipeline }));
                filterDeals(pipe, stage.id, response.options);
            } else {
                setFilteredDeals([]);
            }
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            console.error("Error fetching deals", error);
        });
    }


    const filterDeals = (pipeline, stage, deals) => {
        setStage(stage);
        setPipeline(pipeline);
        var allDealsPipeline = deals.find((option) => {
            if (option && option.pipeline) { return option.pipeline.id == pipeline } else return false
        }).deals
        allDealsPipeline = allDealsPipeline.filter((deal) => {
            return deal.stage.id == stage
        });
        setFilteredDeals(allDealsPipeline);
    }

    return (
        <Stack spacing={1} style={{ padding: '1rem', height: 'calc(100% - 2rem)', width: '100%', margin: 'auto' }}>
            {crmUpdated &&
                <Alert style={{
                    backgroundColor: "#366E6B", color: "white", position: "absolute", width: "90%", left: "5%", right: "5%",
                    top: "5%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", textAlign: "center"
                }}
                    icon={<CheckIcon style={{ color: "white" }} fontSize="inherit" />}>
                    CRM actualizado correctamente
                </Alert>
            }
            <Backdrop open={loading} style={{ zIndex: 9999 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <TextField
                variant="outlined"
                fullWidth
                value={searchTerm}
                onChange={handleSearchChange}
                slotProps={{
                    input: {
                      startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                      endAdornment: <InputAdornment position="end">{searchTerm && searchTerm.length > 0 && <ClearIcon onClick={() => { 
                        setPipeline(undefined);
                        setStage(-1);
                        setSearchTerm(''); 
                        fetchDeals('', false); 
                    }} />} </InputAdornment>,
                    },
                  }}
                style={{ marginBottom: '1rem' }}
            />

            <Button variant="outlined" onClick={() => setDrawerOpen(true)}>
                Filtros
            </Button>
            <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
                <Typography variant="h6" p={2}>Filtros</Typography>
                <Box p={2} style={{ width: '100%' }} role="presentation">
                    {pipelines.length > 0 && (
                        <>
                            <Typography variant="h6">Pipeline</Typography>
                            <FormControl fullWidth margin="normal">
                            <Select
                            label="Pipeline"
                                value={pipeline}
                                onChange={handleChangePipeline}
                                aria-label="Pipeline"
                            >
                            <MenuItem value={-1}>Selecciona un pipeline</MenuItem>
                            {pipelines.map((pipeline) => (
                                <MenuItem value={pipeline.id}>{pipeline.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        </>
                    )}
                    <Typography variant="h6">Fecha de cierre</Typography>
                    <FormControl fullWidth margin="normal">
                        <Select
                            labelId="sort-label"
                            value={closedate}
                            onChange={(e) => setClosedate(e.target.value)}
                        >
                            <MenuItem value="ASCENDING">Más lejano</MenuItem>
                            <MenuItem value="DESCENDING">Más cercano</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box p={2} style={{ width: '100%', position: 'absolute', bottom: 0 }} role="presentation">
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setDrawerOpen(false);
                            handleSortChange();
                        }}
                    >
                        Aplicar
                    </Button>
                </Box>
            </Drawer>

            <Tabs
                value={stage}
                onChange={handleChangeStage}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                centered
                aria-label="scrollable force tabs example"
                style={{ marginBottom: '2rem' }}
            >
                {stages.map((stage) => (
                    <Tab value={stage.id} label={stage.label} />
                ))}
            </Tabs>

            <Grid container spacing={0}>
                {filteredDeals.map((deal) => (
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                        <AudioRecorderButton
                            title={deal.name}
                            handleAudioSent={(text) => {
                                handleAudioSent(text, dealSelected.contacts[0])
                            }}
                            renderContent={({ handleClickOpenInternal }) => {
                                return <DealCard deal={deal} stages={stages} handleClickOpenInternal={() => {
                                    loadDeal(deal, handleClickOpenInternal)
                                }} />
                            }}
                            renderContentBig={() => {
                                return <DealCardBig deal={dealSelected} stages={stages} />
                            }}
                        />
                    </Grid>
                ))}
            </Grid>
        </Stack>
    );
};

const DealCard = ({ deal, stages, handleClickOpenInternal }) => {
    const [open, setOpen] = useState(false);
    const [selectedStage, setSelectedStage] = useState(deal.stage.id);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleClickOpen = () => {
        setSelectedStage(deal.stage.id);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setError(null);
    };

    const handleStageChange = (event) => {
        setSelectedStage(event.target.value);
    };

    const handleSave = () => {
        setLoading(true);
        setError(null);
        deal.stage = stages.find((stage) => { return stage.id == selectedStage });
        api.CreateDealFromJson({ deal: deal }).then((response) => {
            console.log(response);
            setLoading(false);
            setOpen(false);
        }).catch((error) => {
            console.error("Error crteating deal", error);
            setError("Error al mover el negocio. Por favor, inténtalo de nuevo más tarde.");
            setLoading(false);
        });
    };

    return (
        <Stack
            direction="column"
            alignItems="leading"
            spacing={1}
            style={{ maxWidth: '98%' }}
        >
            <Stack direction="column" spacing={1}>
                <Typography noWrap={true} variant="body1">{deal.name}</Typography>
                <Stack direction="row" spacing={1}>
                    {deal.stage && <Chip label={deal.stage.label} variant="outlined" color="secondary" />}
                    {deal.closedate && <Chip label={dayjs(deal.closedate).format('DD/MM/YYYY')} variant="outlined" color="success" />}
                </Stack>
                <Divider />
                <Stack direction="row" spacing={1}>
                    {deal.status && <Chip label={deal.status} variant="outlined" color="warning" />}
                    {deal.amount && <Chip label={deal.amount + ' €'} variant="outlined" color="success" />}
                </Stack>
            </Stack>

            <Button variant="outlined" onClick={handleClickOpen}>
                Mover a otra etapa
            </Button>
            <Button variant="outlined" onClick={handleClickOpenInternal}>
                Ver detalles
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Mover a otra etapa</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Selecciona la nueva etapa para el negocio.
                    </DialogContentText>
                    {loading && <Typography variant="body2" color="textSecondary">Guardando...</Typography>}
                    {error && <Typography variant="body2" color="error">{error}</Typography>}
                    <Select
                        label="Etapa"
                        value={selectedStage}
                        onChange={handleStageChange}
                        aria-label="Etapa"
                        fullWidth
                        disabled={loading}
                    >
                        {stages.map((stage) => (
                            <MenuItem key={stage.id} value={stage.id}>{stage.label}</MenuItem>
                        ))}
                    </Select>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" disabled={loading}>
                        Cancelar
                    </Button>
                    <Button onClick={handleSave} color="primary" disabled={loading}>
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>
        </Stack>
    );
}

const DealCardBig = ({ deal, stages }) => {

    return (
        <Stack direction="column" spacing={1} alignItems="leading" style={{ width: '100%' }}>
            {deal.contacts.map((contact) => (
                <Contacts.ContactCardBig contact={contact} />
            ))}
            <Typography noWrap={true} variant="body1">{deal.name}</Typography>
            <Stack direction="row" spacing={1}>
                {deal.stage && <Chip label={deal.stage.label} variant="outlined" color="secondary" />}
            </Stack>
            <Divider />
            <Stack direction="row" spacing={1}>
                {deal.status && <Chip label={deal.status} variant="outlined" color="warning" />}
                {deal.amount && <Chip label={deal.amount + ' €'} variant="outlined" color="success" />}
            </Stack>
        </Stack>
    )
}

export default Deals;
